<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class>
      <a-form-model
        ref="resourceTypeForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="上级分类" prop="categoryIds">
          <a-cascader
            placeholder="请选择"
            :options="treeData"
            changeOnSelect
            allowClear
            v-model="info.categoryIds"
            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
          />
        </a-form-model-item>
        <a-form-model-item label="分类名称" prop="name">
          <a-input placeholder="请输入" v-model="info.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="展示小图标">
          <a-upload
            accept="image/png,image/jpeg"
            listType="picture-card"
            :action="$api.file.uploadFile"
            :fileList="fileList"
            :beforeUpload="beforeUpload"
            @preview="handlePreview"
            @change="handleFileChange"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="info.icon" />
          </a-modal>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 5 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            style="margin-left: 10px"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  name: "ResourceTypeInfo",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      info: {
        id: null,
        name: "",
        parentId: null,
        icon: "",
        categoryIds: ["0"],
      },
      rules: {
        categoryIds: [
          {
            type: "array",
            required: true,
            message: "请选择上级分类",
            trigger: "change",
          },
        ],
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
      },
      treeData: [],
      fileList: [],
      previewVisible: false,
    };
  },
  mounted() {
    _this = this;
    _this.getTree();
    if (_this.infos.parentId != 0) {
      _this.getParentChain(_this.infos.parentId);
    }
    if (_this.infos.id != 0) {
      _this.getInfo();
    }
  },
  methods: {
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    async getTree() {
      let res = await _this.$api.resourceType.getTypeTree();
      if (res.errorCode == _this.$msg.responseCode.success) {
        let allData = [
          {
            name: "根目录",
            parentId: "0",
            id: "0",
            children: res.data,
          },
        ];
        _this.treeData = allData;
      } else {
        _this.$message.error(res.errorMsg);
      }
    },
    /**获取详情 */
    getInfo() {
      _this.isloading = true;
      _this.$api.resourceType
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info.id = res.data.id;
            _this.info.name = res.data.name;
            _this.info.parentId = res.data.parentId;
            _this.info.icon = res.data.icon;
            if (res.data.icon) {
              _this.fileList.push({
                uid: 1,
                name: "icon.png",
                status: "done",
                url: res.data.icon,
              });
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    getParentChain(id) {
      _this.$api.resourceType
        .getParentIdList(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info.categoryIds = _this.info.categoryIds.concat(res.data);
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.resourceTypeForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let formData = {
            name: _this.info.name,
            icon: _this.info.icon,
            hierarchy: _this.info.categoryIds.length,
          };
          formData.parentId =
            _this.info.categoryIds[_this.info.categoryIds.length - 1];
          if (_this.infos.id != 0) {
            formData.id = _this.infos.id;
          }
          _this.$api.resourceType
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      _this.previewVisible = false;
    },
    handlePreview() {
      _this.previewVisible = true;
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      let isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        _this.$message.error("文件不能超过1MB!");
      }
      return isJPGPNG && isLt1M;
    },
    handleFileChange(fileInfo) {
      _this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != _this.$msg.responseCode.success
        ) {
          _this.$message.error("上传失败");
        } else {
          _this.info.icon = fileInfo.file.response.data.path;
        }
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
      } else if (fileInfo.file.status == "removed") {
        _this.info.icon = "";
      }
    },
  },
};
</script>
<style>
</style>