<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class="pxkeji-right-box">
      <pxkeji-btn
        name="添加"
        class="pxkeji_basic_btn"
        @click="showInfo(0, 0)"
        type="primary"
        :roles="['资料管理-新增分类']"
      />
      <a-tree :replaceFields="replaceFields" :treeData="list.datas" showLine>
        <a-icon slot="switcherIcon" type="caret-down" style="font-size: 24px" />
        <template slot="custom" slot-scope="item">
          <span>{{ item.name }}</span>
          <pxkeji-btn
            name="添加"
            size="small"
            @click="showInfo(0, item.id)"
            type="link"
            :roles="['资料管理-新增分类']"
          />
          <pxkeji-btn
            name="编辑"
            size="small"
            @click="showInfo(item.id, item.parentId)"
            type="link"
            :roles="['资料管理-修改分类']"
          />
          <a-popconfirm @confirm="delInfo(item.id)">
            <div slot="title">是否确认删除此分类？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              size="small"
              style="color: red"
              type="link"
              :loading="isloading"
              :roles="['资料管理-删除分类']"
            />
          </a-popconfirm>
        </template>
      </a-tree>
    </div>

    <a-modal
      :title="info.title"
      v-model="info.visible"
      width="800px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ResourceTypeInfo @callbackMethod="save" :infos="info" />
    </a-modal>
  </a-spin>
</template>
<script>
import ResourceTypeInfo from "./ResourceTypeInfo.vue"; //编辑

let _this;
export default {
  name: "ResourceTypeList",
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      replaceFields: { children: "children", title: "name", key: "id" },
      list: {
        datas: [],
      },
      info: {
        title: "",
        visible: false,
        id: 0,
      },
    };
  },
  components: {
    ResourceTypeInfo,
  },
  mounted() {
    _this = this;
    _this.getTypeTree();
  },
  methods: {
    getTypeTree() {
      //获取列表
      _this.isloading = true;
      _this.$api.resourceType
        .getTypeTree()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data;
            _this.changeTreeData(_this.list.datas);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    changeTreeData(item) {
      for (let itemKey in item) {
        item[itemKey].scopedSlots = { title: "custom" };
        if (item[itemKey].children && item[itemKey].children.length > 0) {
          _this.changeTreeData(item[itemKey].children);
        }
      }
    },
    showInfo(id, parentId) {
      _this.info.title = id != 0 ? "编辑" : "新增";
      _this.info.id = id;
      _this.info.parentId = parentId;
      _this.info.visible = true;
    },
    save() {
      _this.info.visible = false;
      _this.getTypeTree();
    },
    delInfo(id) {
      _this.isloading = true;
      _this.$api.resourceType
        .removeById(id)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getTypeTree();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}
</style>

